/* Navigation Bar */

.navbar {
    background-color: inherit;
    padding: 5px 10px;
}

.navbar .container-fluid {
    background-color: #fafafa;
    width: 100%;
    padding: 5px 15px;
    /*  text-align: center;*/
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
}

.navbar-brand {
    font-family: Nexa-Thin;
}

.navbar-toggler {
    border-radius: 20px;
}

/* ------------- */


input {
    font-family: sans-serif;
}

.input-sm {
    font-size: 0.8em;
}

#input-user,
#input-pwd {
    max-width: 160px;
}

#input-user {
    border-radius: 20px 2px 2px 20px;
}

#input-pwd {
    border-radius: 2px 20px 20px 2px;
}

.no-hover:hover {
    cursor: auto;
    background-color: inherit;
}

.btn-outline-success {
    color: #00C968;
    border-color: #00C968;
}

.btn-outline-success:hover {
    /*  color: #FFFFFF;*/
    background-color: #00C968;
    border-color: #00C968;
}