@font-face {
    font-family: Nexa-Regular;
    src: url('font/Nexa/Non-commercial/Nexa/WEB/Nexa-Trial-Regular.woff2') format('woff2'), url('font/Nexa/Non-commercial/Nexa/WEB/Nexa-Trial-Regular.woff') format('woff'), url('font/Nexa/Non-commercial/Nexa/TTF/Nexa-Trial-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Nexa-Thin;
    src: url('font/Nexa/Non-commercial/Nexa/WEB/Nexa-Trial-Thin.woff2') format('woff2'), url('font/Nexa/Non-commercial/Nexa/WEB/Nexa-Trial-Thin.woff') format('woff'), url('font/Nexa/Non-commercial/Nexa/TTF/Nexa-Trial-Thin.ttf') format('truetype');
}

@font-face {
    font-family: Nexa-Light;
    src: url('font/Nexa/Commercial/Nexa_V2_2020/WEB/NexaText-Light.eot');
    src: url('font/Nexa/Commercial/Nexa_V2_2020/WEB/NexaText-Light.eot?#iefix') format('embedded-opentype'), url('font/Nexa/Commercial/Nexa_V2_2020/WEB/NexaTextDemo-Light.woff2') format('woff2'), url('font/Nexa/Commercial/Nexa_V2_2020/WEB/NexaTextDemo-Light.woff') format('woff'), url('font/Nexa/Commercial/Nexa_V2_2020/TTF/NexaDemo-Light.ttf') format('truetype');
}

body,
html {
    font-family: Nexa-Regular, sans-serif;
    width: 100%;
    height: 100%;
    background-color: #EBFBFA;
}

.bg-image {
    background: #EBFBFA url(img/Living-logo/vector/isolated-layout_living.svg) no-repeat center center fixed;
    background-size: 100% 80%;
    /*  background-position: 0px 56px; minus nav-bar height for centering */
}



/* Buttons */

.btn {
    border-radius: 100px;
}

.btn-primary {
    background-color: #00C968;
    border-color: #00C968;
}

.btn-primary:hover {
    background-color: #009A50;
    border-color: #009A50;
}

.btn-secondary {
    background-color: #087EBE;
    border-color: #087EBE;
}

.btn-secondary:hover {
    background-color: #055D8C;
    border-color: #055D8C;
}

.btn-outline-success {
    color: #00C968;
    border-color: #00C968;
}

.btn-outline-success:hover {
    /*  color: #FFFFFF;*/
    background-color: #00C968;
    border-color: #00C968;
}

.btn-xl {
    font-size: 1.0em;
    padding: 5px 20px;
    margin: 5px;
}


/* ------- */

.buffer {
    height: 14rem;
}

.containerbox {
    /* margin: 10px; */
    padding: 10px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    background-color: #fafafa;
    border-radius: 10px;
    min-height: 100px;
    max-height: calc(100vh - 120px);
    overflow: auto;
}

.h-200 {
    height: 200px;
}
